exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-details-js": () => import("./../../../src/pages/AboutDetails.js" /* webpackChunkName: "component---src-pages-about-details-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/Blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/Careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-details-js": () => import("./../../../src/pages/ContactDetails.js" /* webpackChunkName: "component---src-pages-contact-details-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/Hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-page-js": () => import("./../../../src/pages/PortfolioPage.js" /* webpackChunkName: "component---src-pages-portfolio-page-js" */),
  "component---src-pages-services-page-js": () => import("./../../../src/pages/ServicesPage.js" /* webpackChunkName: "component---src-pages-services-page-js" */),
  "component---src-pages-team-details-js": () => import("./../../../src/pages/TeamDetails.js" /* webpackChunkName: "component---src-pages-team-details-js" */),
  "component---src-pages-under-development-phase-js": () => import("./../../../src/pages/UnderDevelopmentPhase.js" /* webpackChunkName: "component---src-pages-under-development-phase-js" */),
  "component---src-templates-blog-details-js": () => import("./../../../src/templates/BlogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-portfolio-details-js": () => import("./../../../src/templates/PortfolioDetails.js" /* webpackChunkName: "component---src-templates-portfolio-details-js" */),
  "component---src-templates-service-details-js": () => import("./../../../src/templates/ServiceDetails.js" /* webpackChunkName: "component---src-templates-service-details-js" */)
}

